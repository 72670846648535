<template>
  <header class="flex items-center justify-center w-full h-screen bg-gray-400">
    <h1 class="text-3xl font-medium tracking-wide text-center text-white md:w-1/2">
      <span class="block m-2 text-4xl uppercase md:text-6xl">
        {{ $t('News.header.h1') }}
      </span>
    </h1>
  </header>
  <div v-if="post" class="container py-40 mx-auto md:px-32">
    <div class="shadow-lg rounded-xl">
      <img
        :src="URL + post.image"
        alt=""
        loading="lazy"
        class="object-cover w-full bg-center rounded-lg h-60"
      />
      <div class="relative p-5">
        <h1 class="text-5xl font-medium text-dark">
          {{ locale == 'ar' ? post.title_ar : post.title_en }}
        </h1>
        <span class="block my-3 text-primary">
          {{ new Date(post.created_at).toDateString() }}
        </span>
        <span class="block w-full h-1 my-3 rounded-md bg-primary"></span>
        <p class="my-3 text-lg text-black line-clamp">
          {{ locale == 'ar' ? post.content_ar : post.content_en }}
        </p>
        <div class="transform translate-y-28">
          <Subscribe />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Subscribe from '../components/layouts/Subscribe.vue'

export default {
  name: 'NewsDetails',
  data() {
    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
      post: null,
    }
  },
  created() {
    this.post = this.$store.state.specificPost
  },
  components: {
    Subscribe,
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
}
</script>
